import { JSX } from 'react';
import { Button, Heading, Layout, Link, Text } from '../../components/core';
import { PageContent, PageCrisis } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';

export const TherapyNoSelfReferErrorPage = (): JSX.Element => {
  useTitle('Therapy Eligibility');

  const navigate = useNavigate();

  const onReturnHomePress = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <PageContent testID="therapy-no-self-refer-page">
      <Layout.VStack space={7}>
        <Layout.VStack space={5}>
          <Heading.h3>Campus referral required</Heading.h3>

          <Text.para>
            To sign up for clinical care with Mantra, you will need a referral from an authorized
            campus staff member.
          </Text.para>

          <Heading.h5>
            Contact your school counseling center, or refer to your institution's mental health
            resources on their website.
          </Heading.h5>

          <Text.para>
            If you need further assistance or believe this is an error,{' '}
            <Link.para bold to="mailto:hi@mantrahealth.com">
              please contact us
            </Link.para>
            .
          </Text.para>

          <Button.secondaryLarge
            testID="therapy-no-self-refer-return-home-button"
            onPress={onReturnHomePress}
          >
            Return to Care Hub
          </Button.secondaryLarge>
        </Layout.VStack>

        <PageCrisis />
      </Layout.VStack>
    </PageContent>
  );
};
