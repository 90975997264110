import { castArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Slides } from '../../../components/slides/Slides';
import { AssessmentForm } from '../../../content/assessment/components/AssessmentForm';
import { AssessmentContinueArgs } from '../../../content/assessment/components/AssessmentSlideTypes';
import { getQuestions } from '../../../content/assessment/utils/assessmentUtils';
import { Questionnaire } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { useFindCareContext } from '../context/findCareContext';
import { useFindCareSlideContext } from '../context/findCareSlideContext';
import { FindCareCrisisSupportModal } from '../FindCareCrisisSupportModal';
import { useFindCareRedirect } from '../hooks/useFindCareRedirect';

type FindCareAssessmentSlidesProps = {
  assessmentType: string;
  questionnaire: Questionnaire;
  connectNowEnabled: boolean;
};

export const FindCareAssessmentSlides = ({
  assessmentType,
  questionnaire,
  connectNowEnabled,
}: FindCareAssessmentSlidesProps): JSX.Element => {
  const navigate = useNavigate();
  const { answersByAssessment, setAnswer } = useFindCareContext();
  const findCareSlideContext = useFindCareSlideContext();

  const existingValues = useMemo(() => {
    const answers = answersByAssessment[questionnaire.key];
    if (answers === undefined) {
      return undefined;
    }

    return Object.values(answers).map(answer => ({
      key: answer.questionKey,
      value: answer.answerKeys ?? undefined,
      manualInput: answer.manualInput ?? undefined,
    }));
  }, [answersByAssessment]);

  const [showConnectNow, setShowConnectNow] = useState(false);

  const { questions, slides } = useMemo(() => {
    const theQuestions = getQuestions(questionnaire, existingValues);

    return { questions: theQuestions, slides: theQuestions.map(() => AssessmentForm) };
  }, [questionnaire]);

  const { nextSlide, registerNavigation, registerSlides, triggerNextSlide } = findCareSlideContext;

  useFindCareRedirect();

  useEffect(() => {
    if (slides === undefined) {
      return;
    }

    registerNavigation((newSlideNumber: string): void => {
      navigate(getRoute('findCareAssessments', { assessmentType, slide: newSlideNumber }));
    });

    registerSlides(slides);
  }, [assessmentType]);

  const onSlidesContinue = (): void => {
    if (nextSlide) {
      triggerNextSlide();
      return;
    }

    if (assessmentType === 'mental-health-history') {
      navigate(getRoute('findCarePreQuantitative', {}));
    }
    if (assessmentType === 'phq') {
      navigate(getRoute('findCareAssessments', { assessmentType: 'gad', slide: '1' }));
    }
    if (assessmentType === 'gad') {
      // this is the last slide in the last assessment, navigate to submission page.
      navigate(getRoute('findCareReviewAnswers', {}));
    }
  };

  const onSubmitAnswer = ({ question, answer }: AssessmentContinueArgs): void => {
    setAnswer(question.assessmentKey, question.key, answer);

    const answerKey = answer.answerKeys;

    const answerTriggersCrisisModal = shouldShowCrisisModal(answerKey);
    if (!answerTriggersCrisisModal) {
      onSlidesContinue();
      return;
    }

    // Trigger crisis modal in response to answer if enabled
    if (connectNowEnabled) {
      setShowConnectNow(true);
      return;
    }
    onSlidesContinue();
  };

  const onConnectNowModalClose = (): void => {
    setShowConnectNow(false);
    onSlidesContinue();
  };

  return (
    <>
      {showConnectNow && (
        <FindCareCrisisSupportModal
          onContinue={onConnectNowModalClose}
          onPress={() => navigate(getRoute('connectNowHome', {}))}
        />
      )}

      <Slides context={findCareSlideContext} questions={questions} onContinue={onSubmitAnswer} />
    </>
  );
};

/**
 * Logic shared between ConnectNow to determine if assessment answer should trigger crisis
 * popup
 */
const shouldShowCrisisModal = (answerKeys?: string[] | null): boolean => {
  if (answerKeys == null) {
    return false;
  }

  const YES_WITHIN_PAST_MONTH = 'yes-within-past-month';
  const YES_7_PLUS_DAYS = 'yes-7-plus-days';
  const valueToCheck = [YES_WITHIN_PAST_MONTH, YES_7_PLUS_DAYS];

  return castArray(answerKeys).some(answerKey => valueToCheck.includes(answerKey));
};
