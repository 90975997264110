import { useToast } from 'native-base';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigateFromHubToPatientPortalLazyQuery, useTherapyContextUserQuery } from '../graphQL';
import { getRoute, useExternalNavigate, useNavigate } from '../routes';
import { parseMrn, parseMrnIntoRoute } from '../utils/mrn';
import Sentry from '../utils/sentry';

type NavigateToPatientPortalProps = {
  mrn?: string;
  onCompleted?: () => void;
  onError?: () => void;
};

type TherapyContextType = {
  hasLoaded: boolean;
  orgName: string;
  navigateToPatientPortal: (props?: NavigateToPatientPortalProps) => void;
  navigateToTherapy: () => Promise<void>;
  orgCanAccessTherapy: boolean;
  orgHasClinicalSelfReferrals: boolean;
};

const TherapyContext = createContext<TherapyContextType>({
  hasLoaded: false,
  orgName: '',
  navigateToPatientPortal: () => undefined,
  navigateToTherapy: async () => undefined,
  orgCanAccessTherapy: false,
  orgHasClinicalSelfReferrals: false,
});

export const TherapyConsumer = TherapyContext.Consumer;

export const TherapyProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();
  const toast = useToast();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [orgName, setOrgName] = useState<string>('');
  const [canRedirect, setCanRedirect] = useState(false);
  const [orgCanAccessTherapy, setOrgCanAccessTherapy] = useState(false);
  const [orgHasClinicalSelfReferrals, setOrgHasClinicalSelfReferrals] = useState(false);

  const [queryForPortalLoginUrl] = useNavigateFromHubToPatientPortalLazyQuery();

  useTherapyContextUserQuery({
    onCompleted: ({ onboardedHubUser }) => {
      setOrgName(onboardedHubUser?.organization?.name ?? '');
      setCanRedirect(onboardedHubUser?.canRedirect ?? false);
      setOrgCanAccessTherapy(onboardedHubUser?.organizationCanAccessTherapy ?? false);
      setOrgHasClinicalSelfReferrals(
        onboardedHubUser?.organizationHasClinicalSelfReferrals ?? false,
      );
      setHasLoaded(true);
    },
  });

  // Potentially consolidate with therapyCardRoute in useHomePage
  const navigateToTherapy = async (): Promise<void> => {
    if (canRedirect) {
      navigateToPatientPortal();
      return;
    }

    if (!orgHasClinicalSelfReferrals) {
      navigate(getRoute('therapyNoSelfRefer', {}));
      return;
    }

    navigate(getRoute('therapy', {}));
  };

  const navigateToPatientPortal = ({
    mrn,
    onCompleted,
    onError,
  }: NavigateToPatientPortalProps = {}): void => {
    if (!orgCanAccessTherapy) {
      // This org can't access therapy, so send them home.
      navigate(getRoute('home', {}));
      return;
    }

    const onLoginError = (errorOrMessage: Error | string): void => {
      Sentry.captureException(
        typeof errorOrMessage === 'string' ? new Error(errorOrMessage) : errorOrMessage,
      );

      toast.show({
        description: 'Could not log you into the patient portal.',
      });

      onError?.();
    };

    void queryForPortalLoginUrl({
      variables: { mrn },
      onError: onLoginError,
      onCompleted: ({ navigateFromHubToPatientPortal3 }) => {
        const responseType = navigateFromHubToPatientPortal3.__typename;

        if (responseType === 'NavigateFromHubToPatientPortalError') {
          const { errorCode } = navigateFromHubToPatientPortal3;

          if (errorCode === 'VerifyEligibility') {
            navigate(getRoute('therapyEligibility', {}));
            return;
          }

          if (errorCode === 'NoClinicalSelfReferral') {
            navigate(getRoute('therapyNoSelfRefer', {}));
            return;
          }

          onLoginError(
            `NavigateFromHubToPatientPortal3: Invalid state error returned: ${errorCode as string}`,
          );
          return;
        }

        if (responseType === 'NavigateFromHubToPatientPortalForward') {
          const { externalRedirect } = navigateFromHubToPatientPortal3;

          if (externalRedirect === undefined || externalRedirect === '') {
            onLoginError('NavigateFromHubToPatientPortal3: No external redirect found.');
            return;
          }

          externalNavigate(externalRedirect);
          onCompleted?.();
          return;
        }

        if (responseType === 'NavigateFromHubToPatientPortalReauth') {
          const { mrn: reauthMrn } = navigateFromHubToPatientPortal3;

          if (!parseMrn(reauthMrn)) {
            onLoginError(`NavigateFromHubToPatientPortal3: Invalid MRN: ${reauthMrn}`);
            return;
          }

          const { to, toOptions } = parseMrnIntoRoute(reauthMrn);

          navigate(to, { replace: true, ...toOptions });
          return;
        }

        onLoginError(
          `NavigateFromHubToPatientPortal3: Invalid typename: ${responseType as string}`,
        );
      },
    });
  };

  const providerValue: TherapyContextType = {
    hasLoaded,
    orgName, // Temporary until therapy copy can come from the api.
    navigateToPatientPortal,
    navigateToTherapy,
    orgCanAccessTherapy,
    orgHasClinicalSelfReferrals,
  };

  return <TherapyContext.Provider value={providerValue}>{children}</TherapyContext.Provider>;
};

export const useTherapyContext = (): TherapyContextType => {
  return useContext(TherapyContext);
};
