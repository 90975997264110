import { useMemo } from 'react';
import { ANY, Portals } from '../../../content/portal/portals';
import { usePortalContext } from '../../../contexts/portalContext';
import {
  GetCareRecommendationStatusForUserQuery,
  useGetCareRecommendationStatusForUserQuery,
  useHomePageUserQuery,
  useOrganizationConnectNowQuery,
  useOrganizationHasCoachingQuery,
} from '../../../graphQL';
import { RoutableKeys } from '../../../routes';

type UseHomePageReturn =
  | {
      loading: true;
      data: undefined;
    }
  | {
      loading: false;
      data: {
        orgName: string;
        recommendationStatus:
          | GetCareRecommendationStatusForUserQuery['getCareRecommendationStatusForUser']
          | undefined;
        therapyCardRoute: Extract<
          RoutableKeys,
          'therapy' | 'patientPortal' | 'therapyNoSelfRefer' | 'home'
        >;
        userGreeting: string;
        show: {
          campusResources: boolean;
          coaching: boolean;
          findCare: boolean;
          connectNow: boolean;
          studentReferral: boolean;
          therapy: boolean;
          togetherAll: boolean;
        };
      };
    };

/**
 * Loads most of the information needed to display the home page.
 */
export const useHomePage = (): UseHomePageReturn => {
  const { onlyIfPortalIsActive } = usePortalContext();

  const { data: userData, ...userState } = useHomePageUserQuery();
  const { data: orgHasCoachingData, ...orgCoachingState } = useOrganizationHasCoachingQuery();
  const { data: orgHasConnectNowData, ...orgConnectNowState } = useOrganizationConnectNowQuery();
  const { data: recommendationStatusForUser, ...userCareRecState } =
    useGetCareRecommendationStatusForUserQuery();

  const isLoading: boolean = useMemo(() => {
    const states = [userState, orgCoachingState, orgConnectNowState, userCareRecState];

    return states.some(({ loading }) => loading);
  }, [userState, orgCoachingState, orgConnectNowState, userCareRecState]);

  if (isLoading) {
    return {
      data: undefined,
      loading: true,
    };
  }

  const onboardedHubUser = userData?.onboardedHubUser;
  const recommendationStatus = recommendationStatusForUser?.getCareRecommendationStatusForUser;

  const orgName = onboardedHubUser?.organization?.name ?? '';
  const userName = onboardedHubUser?.preferredName ?? '';
  const userGreeting = userName !== '' ? `, ${userName}` : '';

  const showCampusResources = onboardedHubUser?.organizationCanAccessCampusResources ?? false;
  const showCoaching = orgHasCoachingData?.onboardedHubUser.organizationHasCoaching ?? false;
  const showFindCare = onlyIfPortalIsActive(ANY(Portals.Loca, Portals.LocaWellness), true, false);
  const showConnectNow = orgHasConnectNowData?.organizationConnectNow?.connectNowEnabled ?? false;
  const showStudentReferral = onboardedHubUser?.organizationHasSelfReferrals ?? false;
  const orgHasClinicalSelfReferrals =
    onboardedHubUser?.organizationHasClinicalSelfReferrals ?? false;

  const anyClinicalPortalActive = onlyIfPortalIsActive(
    ANY(Portals.Therapy, Portals.NonLocaTherapyGateway, Portals.Psychiatry),
    true,
    false,
  );
  const therapyOrPyschPortalActive = onlyIfPortalIsActive(
    ANY(Portals.Therapy, Portals.Psychiatry),
    true,
    false,
  );
  const nonLocaTherapyGatewayActive = onlyIfPortalIsActive(
    Portals.NonLocaTherapyGateway,
    true,
    false,
  );

  const showTherapy = anyClinicalPortalActive;
  const showTogetherAll = onboardedHubUser?.organizationCanAccessTogetherAll ?? false;

  // Potentially consolidate with navigateToTherapy in therapy
  const therapyCardRoute = (() => {
    if (therapyOrPyschPortalActive) {
      return 'patientPortal';
    } else if (nonLocaTherapyGatewayActive && orgHasClinicalSelfReferrals) {
      return 'therapy';
    } else if (nonLocaTherapyGatewayActive && !orgHasClinicalSelfReferrals) {
      return 'therapyNoSelfRefer';
    }
    return 'home';
  })();

  return {
    data: {
      orgName,
      recommendationStatus,
      therapyCardRoute,
      userGreeting,
      show: {
        campusResources: showCampusResources,
        coaching: showCoaching,
        findCare: showFindCare,
        connectNow: showConnectNow,
        studentReferral: showStudentReferral,
        therapy: showTherapy,
        togetherAll: showTogetherAll,
      },
    },
    loading: false,
  };
};
